<template>
  <Card dense class="content-menus">
    <template v-for="(menu, index) in menus" :key="`menu-${index}`">
      <div
        :class="{ 'mt-2': index !== 0 }"
        v-if="menu.authority ? acl.can(menu.authority) : true"
      >
        <router-link
          :to="{
            name: menu.routerName || ROUTE_NAMES.notFound,
            query: menu.query,
            params: menu.params
          }"
        >
          <div class="content-menus-item" :class="{ current: menu.current }">
            <Typography :color="menu.current ? 'primary' : 'default'">
              {{ menu.label }}
            </Typography>
          </div>
        </router-link>
      </div>
      <template v-if="menu.children">
        <div
          v-for="(child, childIndex) in menu.children"
          :key="`child-${childIndex}`"
          class="mt-2"
        >
          <router-link
            :to="{
              name: child.routerName || ROUTE_NAMES.notFound,
              query: child.query,
              params: child.params
            }"
            v-if="child.authority ? acl.can(child.authority) : true"
          >
            <Flex
              align-center
              class="content-menus-item child"
              :class="{ current: child.current }"
            >
              <TreeSvg
                v-if="childIndex === menu.children.length - 1"
                class="tree mr-1"
              />
              <TreeMiddleSvg v-else class="tree mr-1" />
              <Typography>{{ child.label }}</Typography>
            </Flex>
          </router-link>
        </div>
      </template>
    </template>
  </Card>
</template>
<script>
import { defineComponent } from 'vue';
import { ROUTE_NAMES } from '@/router';
import Flex from '@/components/layout/Flex.vue';
import Card from '@/components/ui/Card.vue';
import Typography from '@/components/ui/Typography.vue';
import TreeSvg from '@/assets/images/tree.svg?inline';
import TreeMiddleSvg from '@/assets/images/tree-middle.svg?inline';
import { useAcl } from '@/store/acl';

export default defineComponent({
  name: 'SubMenu',
  components: { Card, Flex, TreeSvg, TreeMiddleSvg, Typography },
  props: {
    menus: Array
  },
  setup() {
    const acl = useAcl();
    return {
      ROUTE_NAMES,
      acl
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/effect';
@import '@/styles/spacing';

.content-menus {
  &-item {
    border-radius: 4px;
    padding: $spacing-base * 2;

    .tree {
      color: var(--dark-25-hex-color);
    }

    &.child {
      padding: $spacing-base * 2;
      margin-left: $spacing-base * 2;
    }

    &.current {
      background-color: var(--primary-light-color);
      .tree {
        color: var(--chart-blue-1);
      }
    }

    @extend .hover-background;
  }
}
</style>
