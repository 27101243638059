<template>
  <LayoutWithSidebar
    :breadcrumbs="breadcrumbs"
    :title="getTitle()"
    isMainBoxDense
  >
    <template v-if="getHelpPageLink()" v-slot:button-content>
      <Flex align-center>
        <Icon name="question" class="mr-1" fill />
        <Typography size="m" color="link" class="ui-text-link mr-5">
          <a :href="getHelpPageLink()" target="_blank">
            {{ '使い方' }}
          </a>
        </Typography>
      </Flex>
    </template>
    <Flex>
      <Box flex="1">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </Box>
    </Flex>
  </LayoutWithSidebar>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { SETTINGS_ROUTES, CUSTOM_ROUTES, COMPANY_ROUTES } from '@/router';
import Box from '@/components/layout/Box';
import Flex from '@/components/layout/Flex';
import LayoutTwoColumn from '@/components/layout/LayoutTwoColumn';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar';
import Card from '@/components/ui/Card';
import SubMenu from '@/components/ui/SubMenu';
import Typography from '@/components/ui/Typography.vue';
import Icon from '@/components/ui/Icon.vue';
import { AclRules } from '@/plugins/acl';

export default defineComponent({
  name: 'settingIndex',
  components: {
    Box,
    Card,
    Flex,
    SubMenu,
    LayoutTwoColumn,
    LayoutWithSidebar,
    Typography,
    Icon
  },
  setup() {
    const route = useRoute();
    const breadcrumbs = ref({
      parents: [
        { name: COMPANY_ROUTES.top, label: 'ホーム' },
        { label: '設定' }
      ],
      current: { label: '商品／ブランド' }
    });

    const getTitle = () => {
      switch (route.name) {
        case SETTINGS_ROUTES.basic:
          return '基本設定';
        case SETTINGS_ROUTES.brand:
          return '商品／ブランドの名寄せ';
        case SETTINGS_ROUTES.brandEdit:
          return '商品／ブランドの編集';
        default:
          break;
      }
    };

    const getHelpPageLink = () => {
      switch (route.name) {
        case SETTINGS_ROUTES.basic:
          return '';
        case SETTINGS_ROUTES.brand:
          return 'https://help.tval.jp/7dce4d3573044fac863efda75e19f844';
        case SETTINGS_ROUTES.brandEdit:
          return 'https://help.tval.jp/22ca2491c40b446fbfc90c9e3a4e50f8';
        default:
          break;
      }
    };

    watchEffect(() => {
      switch (route.name) {
        case SETTINGS_ROUTES.basic:
          breadcrumbs.value.current = { label: '基本設定' };
          break;
        case SETTINGS_ROUTES.brandEdit:
          breadcrumbs.value.parents = [
            { name: COMPANY_ROUTES.top, label: 'ホーム' },
            { label: '設定' },
            { name: SETTINGS_ROUTES.brand, label: '商品／ブランド' }
          ];
          breadcrumbs.value.current = { label: '編集' };
          break;
        case SETTINGS_ROUTES.brand:
          breadcrumbs.value.current = { label: '商品／ブランド' };
          break;
        default:
          break;
      }
    });

    return {
      breadcrumbs,
      SETTINGS_ROUTES,
      CUSTOM_ROUTES,
      route,
      getTitle,
      getHelpPageLink,
      AclRules
    };
  }
});
</script>
