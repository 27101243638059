<template>
  <Flex class="layout-two-column pa-6">
    <Flex full-width>
      <div class="column-item side mr-6">
        <slot name="side" />
      </div>
      <div class="column-item main">
        <slot name="main" />
      </div>
    </Flex>
  </Flex>
</template>
<script>
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';

export default defineComponent({
  name: 'LayoutTwoColumn',
  components: { Flex }
});
</script>
<style lang="scss">
@import '@/styles/layout';
@import '@/styles/spacing';

.layout-two-column {
  .column-item {
    overflow-wrap: break-word;

    &.side {
      width: $side-content-width;
      min-width: $side-content-min-width;
    }

    &.main {
      flex: 1;
    }
  }
}
</style>
